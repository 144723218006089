﻿//全局Event对象
ZCEvent = new Object;
ZCData = new Object;
ZCEvent.Blog = {};

ZCEvent.Confirm = function (okCallback, cancelCallback) {
  var confirmBox = $('#ConfirmBox');
  confirmBox.modal({
    closable: true,
    onDeny: function () {
      if (cancelCallback) { cancelCallback(); }
    },
    onApprove: function () {
      if (okCallback) { okCallback(); }
    }
  }).modal('show');
}

let intervalId = -1;
ZCEvent.ConfirmCountDownAsync = async function (countDownSeconds) {
  var counter = countDownSeconds;
  const confirmBox = $('#ConfirmBox');
  const positiveButton = $('.positive.button', confirmBox);

  clearInterval(intervalId);
  $('.counter', positiveButton).remove();
  positiveButton.removeClass('disabled');

  positiveButton.append(`<span class="counter"> (${counter}) </span>`);
  positiveButton.addClass('disabled');
  intervalId = setInterval(() => {
    if (counter > 0) {
      counter--;
      $('.counter', positiveButton).remove();
      positiveButton.addClass('disabled');
      positiveButton.append(`<span class="counter"> (${counter}) </span>`);
    }
    if (counter === 0) {
      clearInterval(intervalId);
      $('.counter', positiveButton).remove();
      positiveButton.removeClass('disabled');
    }
  }, 1000);

  function modalResult() {
    return new Promise(resolve => {
      confirmBox.modal({
        closable: true,
        onDeny: function () {
          resolve(false);
        },
        onApprove: function () {
          resolve(true);
        }
      }).modal('show');
    });
  }

  const result = await modalResult();
  return result;
}

function showLoadingMain() {
  showLoading($("#main"), true);
}

function loadHome() {
  $.ajax({
    type: 'GET',
    url: "/home",
    cache: false,
    dataType: 'text',
    beforeSend: showLoadingMain,
  }).done(function (d) {
    var mainDiv = $("#main");
    mainDiv.html(d);

    $("#nav a").removeClass("active");
    $("#nav-home").addClass("active");
  });
}

function loadBlog(blogId, scroll_to_id) {
  $.ajax({
    type: 'GET',
    url: "/blog",
    cache: false,
    dataType: 'text',
    beforeSend: showLoadingMain,
  }).done(function (d) {
    var mainDiv = $("#main");
    mainDiv.html(d);

    $("#nav a").removeClass("active");
    $("#nav-blog").addClass("active");

    //Load blog
    if (isNaN(blogId)) {
      //显示Index页
      blogFunc.LoadContent("/blog/0", scroll_to_id);
    }
    else {
      blogFunc.LoadContent("/blog/" + blogId, scroll_to_id); // 加载对应博客的内容
      if (blogId > 10) { // 展开分类树到该博客的Entry处(若非特殊页，ID小于10的blog)
        ExpandTreeToEntry(blogId);
      }
    }
  });
}

function loadShop() {
  $.ajax({
    type: 'GET',
    url: "/shop",
    cache: false,
    dataType: 'text',
    beforeSend: showLoadingMain,
  }).done(function (d) {
    var mainDiv = $("#main");
    mainDiv.html(d);

    $("#nav a").removeClass("active");
    $("#nav-shop").addClass("active");
  });
}

function loadProject(projectName, scroll_to_id, postLoadCallback) {
  $.ajax({
    type: 'GET',
    url: "/project/" + projectName,
    cache: false,
    dataType: 'text',
    beforeSend: showLoadingMain,
  }).done(function (d) {
    var mainDiv = $("#main");
    mainDiv.html(d);

    $("#nav a").removeClass("active");
    $(mainDiv).ready(function () {
      if (scroll_to_id) {
        document.getElementById(scroll_to_id)?.scrollIntoView();
      }
      if (postLoadCallback) {
        postLoadCallback();
      }
    });
  });
}

function loadAbout() {
  $.ajax({
    type: 'GET',
    url: "/about",
    cache: false,
    dataType: 'text',
    beforeSend: showLoadingMain,
  }).done(function (d) {
    var mainDiv = $("#main");
    mainDiv.html(d);

    $("#nav a").removeClass("active");
    $("#nav-about").addClass("active");
  });
}

function loadAdmin() {
  $.ajax({
    type: 'GET',
    url: "/Admin",
    cache: false,
    dataType: 'text',
    beforeSend: showLoadingMain,
  }).done(function (d) {
    var mainDiv = $("#main");
    mainDiv.html(d);

    $("#nav a").removeClass("active");


  });
}

//使“回到顶部”按钮生效
(function () {
  var InitEvent_InitBackToTop = function () {
    //当点击跳转链接后，回到页面顶部位置
    $("#back-to-top").click(function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, 100);
    });
  };
  ZCEvent.InitBackToTop = InitEvent_InitBackToTop;
}());

ZCBlogEvent = new Object;

ZCBlogEvent.UniqueURL = new Object;
ZCBlogEvent.Catalog = new Object;


//伪链接滚动到#ID位置功能
(function () {
  var InitEvent_ScrollTo = function (id) {
    if (id == null || id.charAt(0) !== '#') {
      console.log("Error: Scroll target is invalid!");
      return false;
    }
    id = id.replace(/(:|\.|\[|\]|,)/g, "\\$1");//转义特殊字符

    $('html, body').animate({
      scrollTop: $(id).offset().top - $("#Header").height() - $(id).height()
    }, 100);
    return true;
  };
  ZCBlogEvent.ScorllTo = InitEvent_ScrollTo;
}());

jQuery.extend(jQuery.expr[':'], {
  inview: function (elem) {
    var t = $(elem);
    var offset = t.offset();
    var win = $(window);
    var winST = win.scrollTop();
    var elHeight = t.outerHeight(true);

    if (offset.top > winST - elHeight && offset.top < winST + elHeight + win.height()) {
      return true;
    }
    return false;
  }
});

ZCData.LoadedCss = new Array;
//判断是否是已经动态加载的CSS
(function () {
  function in_array(array, value) {
    for (var i = 0; i < array.length; i++) {
      return (array[i][0] === value)
    }
    return false;
  }
  var _IsLoadedCss = function (filename) {
    var result = in_array(ZCData.LoadedCss, filename);
    return result;
  };
  ZCData.IsLoadedCss = _IsLoadedCss;
}());

//动态加载CSS
(function () {
  function loadCSS(filename) {
    var file = document.createElement("link");
    file.setAttribute("rel", "stylesheet");
    file.setAttribute("type", "text/css");
    file.setAttribute("href", filename);
    if (typeof file !== "undefined")
      document.head.appendChild(file);
    return true;
  }
  var _LoadCss = function (filename) {
    if (ZCData.IsLoadedCss(filename)) {
      return true;
    }
    var result = loadCSS(filename);
    if (result) {
      ZCData.LoadedCss.push(filename);
    }
    return result;
  };
  ZCData.LoadCss = _LoadCss;
}());


//登录相关
(function () {

  function requestCreateAccount(pendingCallback, doneCallback) {
    const form = $('#form-register');
    $.ajax({
      type: 'POST',
      url: `/v1/account/create`,
      cache: false,
      data: form.serialize(),
      beforeSend: function () {
        pendingCallback?.();
      }
    }).done(function (result) {
      doneCallback?.(result);
    }).catch(function (error) {
      ZCEvent.ShowFullscreenMsgBox(error.status, error.responseText);
    });
  }

  function requestLogin(pendingCallback, doneCallback) {
    var frm = $('#form-login');
    $.ajax({
      type: "POST",
      url: "/v1/account/login",
      data: frm.serialize(),
      beforeSend: function () {
        pendingCallback?.();
      },
      success: function (result) {
        doneCallback?.(result);
      },
      error: function (error) {
        ZCEvent.ShowFullscreenMsgBox(error.status, error.responseText);
      }
    });
  }

  function showLoginBox() {
    const loginResult = $('.LoginResult');
    ZCEvent.ClearAndHideMessage(loginResult);
    $("#LoginBox").modal({
      onDeny: function () {
        return true;
      },
      onApprove: function () {
        requestLogin(
          () => ZCEvent.SetMessage(loginResult, 'loading', '', ZCText.LoggingIn),
          (result) => {
            if (result.Success) {
              ZCEvent.SetMessage(loginResult, 'positive', ZCText.LoginSucceeded, result.Description);
              if (result.Username === 'cloud') {
                ZCEvent.LoadMain("#admin");
                $("#LoginBox").modal('hide');
              } else {
                loadLoginUserFromServer();
                setTimeout(function () {
                  ZCEvent.LoadUserPortal();
                  $("#LoginBox").modal('hide');
                }, 500);
              }
            } else {
              ZCEvent.SetMessage(loginResult, 'error', ZCText.LoginFailed, result.Description);
            }
          });
        return false;
      }
    })
      .modal('setting', 'closable', false)
      .modal('show');
  }

  function setLoginStateForClient(state) {
    $('.LoginNavDropdown').dropdown({ action: 'hide' });
    if (state) {
      $('.AccountNavButton').hide();
      $('.LoginNavDropdown').show();
    } else {
      Cookies.remove("zwcloud_net_0");
      $('.AccountNavButton').show();
      $('.LoginNavDropdown').hide();
      ZCEvent.LoadMain();//load index page
    }
  }

  function loadLoginUserFromServer() {
    $.ajax({
      type: 'GET',
      url: "/v1/account/get_login_user",
      cache: false,
      dataType: 'json'
    }).done(function (data) {
      if (data != null) {
        $("[data-id='nav-username']").text(data.Name);
        $("[data-id='nav-email']").text(data.Email);
        setLoginStateForClient(true);
      } else {
        $("[data-id='nav-username']").text(' ');
        $("[data-id='nav-email']").text(' ');
        setLoginStateForClient(false);
      }
    }).catch(function (error) {
      ZCEvent.ShowFullscreenMsgBox(error.status, error.responseText);
      //user might have logout from another browser tab
      setLoginStateForClient(false);
    });
  }

  function requestLogout() {
    $.ajax({
      type: "GET",
      url: "/v1/account/logout",
      dataType: 'json',
      success: function (data) {
        if (data.Success) {
          setLoginStateForClient(false);
        }
      },
      error: function (data) {
        console.log(data);
      }
    });
  }

  function logoutInClient() {
    setLoginStateForClient(false);
  }

  ZCEvent.InitLogin = function () {
    {
      const loginCookie = Cookies.get("zwcloud_net_0");
      if (loginCookie != null) {
        loadLoginUserFromServer();
      }
    }

    $('.CreateAccountNavButton').click(function () {
      const createAccountBox = $('#CreateAccountBox');
      const createAccountResult = $('.CreateAccountResult');
      ZCEvent.ClearAndHideMessage(createAccountResult);
      createAccountBox.modal({
        closable: true,
        onDeny: function () {
          return true;
        },
        onApprove: function () {
          requestCreateAccount(
            () => ZCEvent.SetMessage(createAccountResult, 'loading', '', ZCText.CreatingAccount),
            (result) => {
              if (result.Success) {
                ZCEvent.SetMessage(createAccountResult, 'positive', ZCText.CreateAccountSuccess, result.Description);
                setTimeout(() => showLoginBox(), 1000);
              } else {
                ZCEvent.SetMessage(createAccountResult, 'error', ZCText.CreateAccountFailed, result.Description);
              }
            }
          );
          return false;
        }
      }).modal('show');
    });

    $('.LoginNavButton').click(function () {
      const cookieAccepted = Cookies.get('accepts-cookies');
      if (cookieAccepted == null) {
        $('#main').toast({ message: ZCText.NeedToAcceptCookieToLogin });
        $('#cookieNag').nag('show');
        return;
      }

      const loginCookie = Cookies.get("zwcloud_net_0");
      if (loginCookie == null) {
        //user not login yet, show login box
        showLoginBox();
      } else {
        loadLoginUserFromServer();
      }
    });
    $('.LogoutButton').click(function () {
      var confirmBox = $('#LogoutConfirmBox');
      confirmBox.modal({
        closable: true,
        onDeny: function () {
        },
        onApprove: function () {
          requestLogout();
        }
      }).modal('show');
    });
  }

  ZCEvent.LogoutInClient = logoutInClient;
}());

//用户相关
(function () {

  function loadUserPortal() {
    $.ajax({
      type: 'GET',
      url: "/user/portal",
      cache: false,
      dataType: 'text',
      beforeSend: showLoadingMain,
    }).done(function (d) {
      var mainDiv = $("#main");
      mainDiv.html(d);
      $("#nav a").removeClass("active");
    });
  }

  function sendDeactivateMachineRequestToServer(machineId, productId) {
    $.ajax({
      type: 'GET',
      url: `/v1/user/deactivate_machine?machineId=${machineId}&productId=${productId}`,
      cache: false,
      dataType: 'json'
    }).done(function (result) {
      if (result.Success) {
        ZCEvent.MsgBox(ZCText.Message, result.Description);
      } else {
        ZCEvent.MsgBox(ZCText.Error, result.Description);
      }
    }).catch(function (error) {
      if (error.status === 401) {
        ZCEvent.LogoutInClient();
        return;
      }
      ZCEvent.ShowFullscreenMsgBox(error.status, error.responseText);
    });
  }

  const dataMachineIdAttributeName = 'machine-id';

  function createMachineView(machine) {
    const iconColor = machine.IsLive ? "green" : "red";
    const liveStateDesc = machine.IsLive ? ZCText.MachineIsReadyForRemoteDeactivation : ZCText.MachineIsNotReadyForRemoteDeactivation;
    return `
<div class="mini card">
  <div class="content">
    <div class="header"><i class="desktop icon"></i>${machine.MachineName}
      <span data-tooltip="${liveStateDesc}"><i class="${iconColor} circle icon"></i></span>
    </div>
    <div class="description"><i class="microchip icon"></i>${machine.MotherboardName}</div>
  </div>
  <button class="ui small button right floated deactivate-button" title="${ZCText.DeactivateMachineTooltip}"
    data-${dataMachineIdAttributeName}="${machine.MachineId}">
  <i class=" large icons">
    <i class="big dont icon"></i>
    <i class="black desktop icon"></i>
  </i>
  </button>
</div>`;
  };

  function loadOrderMachines(route, nextRow, rowContentContainer, orderId, productId) {
    $.ajax({
      type: 'GET',
      url: `${route}?orderId=${orderId}`,
      cache: false,
      dataType: 'json',
      beforeSend: function () {
        rowContentContainer.html('<div class="ui active loader"></div>');
        nextRow.show();
      }
    }).done(function (machines) {
      if (machines.length === 0) {
        rowContentContainer.html(`<div class="ui items"><div class="ui item">
<i class="large boxes icon"></i><div class="content">${ZCText.NoMachineActivated}</div>
</div></div>`);
      } else {
        let s = '<div class="ui cards">';
        for (let i = 0; i < machines.length; i++) {
          const machine = machines[i];
          s += createMachineView(machine);
        }
        s += '</div>';
        //const s = `${data.length} Machines: <pre>${JSON.stringify(data, null, 2)}</pre>`;
        rowContentContainer.html(s);
        rowContentContainer.find('.deactivate-button').click(function () {
          let machineId = $(this).data(dataMachineIdAttributeName);
          sendDeactivateMachineRequestToServer(machineId, productId);
        });
      }
      nextRow.show();
    }).catch(function (error) {
      if (error.status === 401) {
        ZCEvent.LogoutInClient();
        return;
      }
      rowContentContainer.html(`<div class="ui button">${ZCText.Retry} </div>`);
      rowContentContainer.find('.button').click(function () { loadOrderMachines(route, nextRow, rowContentContainer, orderId); });
      nextRow.show();
    });
  }

  function bindSerialKey(key) {
    $.ajax({
      type: 'GET',
      url: `/v1/user/bindSerialKey?serialKey=${key}`,
      cache: false,
      dataType: 'json'
    }).done(function (result) {
      if (result.Success) {
        loadUserPortal();
      } else {
        ZCEvent.MsgBox("Error", "Error happened when trying to bind the serial key:\n" + result.Description);
      }
    }).catch(function (error) {
      if (error.status === 401) {
        ZCEvent.LogoutInClient();
        return;
      }
      ZCEvent.MsgBox("Error", "Error happened:\n" + error);
    });
  }

  ZCEvent.LoadUserPortal = loadUserPortal;
  ZCEvent.LoadOrderMachines = loadOrderMachines;
  ZCEvent.BindSerialKey = bindSerialKey;


}());

/************************************************************************/
/* ajax link handler                                                    */
/************************************************************************/

/*Home:
https://zwcloud.net
https://zwcloud.net/
*/

/*Blog:
1. home page of blog
  https://zwcloud.net/#blog
  https://zwcloud.net/#blog/
  https://zwcloud.net/#blog/0
2. some blog with optional scroll target
Format:
  https://zwcloud.net/#blog/{blog_id}[?scroll_target]
e.g.
  https://zwcloud.net/#blog/12
  https://zwcloud.net/#blog/12?Intro
*/

/*Project:
Format:
  https://zwcloud.net/#project/{project_name}/[{tab_name}]
e.g.
  https://zwcloud.net/#project/mte/features
  https://zwcloud.net/#project/imgui
  https://zwcloud.net/#project/cgif
*/

/*About:
https://zwcloud.net/#about
*/

/*Admin
https://zwcloud.net/#admin
*/
(function () {
  var _PostLoad = function () {
    $('[data-anchor]').off('click').click(e => {
      const anchor = $(e.target).attr('data-anchor');
      $(anchor)[0].scrollIntoView();
    });
  }
  var _LoadMain = function (src) {
    if (!src || src.length === 0 || src === "/") {
      $("#SideBar").hide();
      loadHome();
    }
    else if (src.startsWith("#blog")) {
      var blogId = parseInt(src.substr(5/*"#blog".length*/ + 1));
      var question_mark_index = src.indexOf("?");
      var scroll_to_id = undefined;
      if (question_mark_index >= 0) {
        scroll_to_id = src.substr(question_mark_index + 1);
      }
      if (document.body.clientWidth > 1199) {
        $("#SideBar").show();
      } else {
        $("#SideBar").hide();
      }
      if ($("#sidecatalog-container").length !== 0) {
        blogFunc.LoadContent("/blog/" + blogId, scroll_to_id); // 加载对应博客的内容
        if (blogId > 10) { // 展开分类树到该博客的Entry处(若非特殊页，ID小于10的blog)
          ExpandTreeToEntry(blogId);
        }
      } else {
        loadBlog(blogId, scroll_to_id);
      }
    }
    else if (src.startsWith("#shop")) {
      $("#SideBar").hide();
      loadShop();
    }
    else if (src.startsWith("#project")) {
      $("#SideBar").hide();
      var tmp = src.split("/");
      loadProject(tmp[1]/*project name*/, tmp[2]/*section id*/, _PostLoad);
    }
    else if (src.startsWith("#about")) {
      $("#SideBar").hide();
      loadAbout();
    }
    else if (src.startsWith("#admin")) {
      $("#SideBar").hide();
      loadAdmin();
    }
    else if (src.startsWith("#user/portal")) {
      $("#SideBar").hide();
      ZCEvent.LoadUserPortal();
    }
    else {//unknown
      $("#SideBar").hide();
      ZCEvent.MsgBox("Message", "The address is invalid. Redirected to home.");
      history.replaceState({ "src": "/" }, null, "/");
      loadHome();
    }

  }

  ZCEvent.LoadMain = _LoadMain;
})();
